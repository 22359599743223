import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet-async'

import { Container, Content } from './styled'
import { LayoutConsumer } from 'contexts/layout-context'
import { ConfigConsumer } from 'contexts/config-context'
import meta from 'const/meta'
import config from 'app-config'
import Modals from 'common/modals'
import PageFooter from 'layouts/PageFooter'
import PageHeader from 'layouts/PageHeader'
import Analytics from 'containers/Analytics'
import Maintenance from './Maintenance'
import get from 'lodash/get'
import DesktopContainer from 'layouts/DesktopContainer'
import SmartBanner from 'react-smartbanner'

export const withSetLayout = Component => {
  // return class extends React.Component {
  return props => {
    return (
      <LayoutConsumer>
        {({ setLayout }) => <Component setLayout={setLayout} {...props} />}
      </LayoutConsumer>
    )
  }
}

// class Root extends React.Component {
const Root = props => {
  return (
    <React.Fragment>
      <Analytics />
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="og:title" content={meta.title} />
        <meta name="og:site_name" content={meta.ogSitename} />
        <meta name="og:description" content={meta.description} />
        <meta name="og:image" content={meta.ogImage} />
        <meta name="og:app_id" content={config.FACEBOOK_APP_ID} />
      </Helmet>
      <ConfigConsumer>
        {p =>
          get(p, 'maintenance.consumer_web') ? (
            <LayoutConsumer>
              {({ header }) => (
                <Container>
                  {header && <DesktopContainer />}
                  <Content>
                    <Maintenance />
                  </Content>
                </Container>
              )}
            </LayoutConsumer>
          ) : (
            <LayoutConsumer>
              {({ header, footer }) => (
                <Container>
                  <SmartBanner title="Heyyou" />
                  {header && <PageHeader location={props.location} />}
                  <Content>{props.children}</Content>
                  {footer && <PageFooter />}
                </Container>
              )}
            </LayoutConsumer>
          )
        }
      </ConfigConsumer>
      <Modals />
    </React.Fragment>
  )
}

Root.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Root
