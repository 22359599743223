import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { Avatar } from '../styled'
import { H4 } from 'components/Text'
import { WHITELABEL_MENU } from '../const'
import events from 'const/analytics-events'
import Icon from 'components.v2/Icon/IconV2'
import withAnalytics from 'enhancers/withAnalytics'
import { ConfigContext } from 'contexts/config-context'

import {
  Outlay,
  ProfileMenuContainer,
  ProfileSection,
  EditButton,
  AvatarSection,
  MenuList,
  MenuItem,
  MenuIcon,
  MenuLink,
  MenuEditLink,
  StyledLink,
} from './styled'

const EditButtonTracked = withAnalytics([
  {
    eventType: 'onClick',
    options: events['HEADER_CLICK_PROFILE'],
  },
])(EditButton)

const MenuLinkItem = withAnalytics()(({ menu, ...rest }) => {
  const config = useContext(ConfigContext)

  const getMenuLabel = menu => {
    return menu.name === 'free'
      ? get(config, 'menu.refer', 'Refer a friend')
      : menu.label
  }

  return (
    <MenuLink
      {...rest}
      key={menu.name}
      to={menu.link}
      target="_blank"
      activeStyle={{ backgroundColor: 'rgba(46, 154, 154, 0.05)' }}
    >
      <MenuItem>
        <MenuIcon src={menu.icon} />
        <H4 size={14} fontWeight={500}>
          {getMenuLabel(menu)}
        </H4>
      </MenuItem>
    </MenuLink>
  )
})

class ProfileMenu extends React.PureComponent {
  editProfile = () => {
    this.props.history.push('/account/profile')
  }
  render() {
    const { avatar } = this.props
    return (
      <Outlay>
        <ProfileMenuContainer>
          <ProfileSection>
            <EditButtonTracked>
              <StyledLink to="/account/profile" target="_blank" rel="noopener">
                <MenuEditLink />
              </StyledLink>
            </EditButtonTracked>
            <AvatarSection>
              <Avatar avatar={avatar} />
              <Icon name={`fas fa-chevron-up`} size={10} />
            </AvatarSection>
          </ProfileSection>
          <MenuList>
            {WHITELABEL_MENU.map(menu => (
              <MenuLinkItem
                key={menu.analytics}
                analytics={[
                  {
                    eventType: 'onClick',
                    options: events[`HEADER_${menu.analytics}`],
                  },
                ]}
                menu={menu}
              />
            ))}
          </MenuList>
        </ProfileMenuContainer>
      </Outlay>
    )
  }
}

ProfileMenu.propTypes = {
  history: PropTypes.object,
  avatar: PropTypes.string,
}

export default withRouter(ProfileMenu)
