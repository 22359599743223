import { createAction } from 'redux-actions'
import get from 'lodash/get'

import { getError } from 'utils/get-message'
import * as api from 'api'
import client from 'client'
import CONFIG from '../../config'
import { unsetGuestCheckoutEmail } from 'store/guest-checkout'
import { RESET_RECENT_ORDERS } from 'pages/order/modules/multi.vendor.order'
import { getConfig } from 'contexts/config-context'

const REFRESH_TOKEN = 'unicorn/auth/REFRESH_TOKEN'
const REDIRECT = 'unicorn/auth/REDIRECT'
const REGISTER = 'unicorn/auth/REGISTER'
const REFERRAL_CODE = 'unicorn/auth/REFERRAL_CODE'

const FORGOT = 'unicorn/auth/FORGOT'
const FORGOT_FAILURE = 'unicorn/auth/FORGOT_FAILURE'
const FORGOT_SUCCESS = 'unicorn/auth/FORGOT_SUCCESS'

const RESET = 'unicorn/auth/RESET'
const RESET_FAILURE = 'unicorn/auth/RESET_FAILURE'
const RESET_SUCCESS = 'unicorn/auth/RESET_SUCCESS'

const CONFIRM = 'unicorn/auth/CONFIRM'
const CONFIRM_FAILURE = 'unicorn/auth/CONFIRM_FAILURE'
const CONFIRM_SUCCESS = 'unicorn/auth/CONFIRM_SUCCESS'

export const LOGOUT = 'unicorn/auth/LOGOUT'
export const LOGIN = 'unicorn/auth/LOGIN'

export const clearError = createAction('unicorn/auth/CLEAR_ERROR')

export const verifyResetToken = token => {
  return async dispatch => {
    try {
      dispatch({ type: RESET })

      const response = await client(
        true,
      ).post('/customers/validate_password_reset_token', { token })

      dispatch({ type: RESET_SUCCESS })

      return Promise.resolve(response)
    } catch (err) {
      dispatch({
        type: RESET_FAILURE,
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: 'Invalid reset password token',
          },
        },
      })
      return Promise.reject(err)
    }
  }
}

export const resetPassword = data => {
  return async dispatch => {
    try {
      dispatch({ type: RESET })

      const response = await client(true).post(
        '/customers/password_reset',
        data,
      )

      dispatch({
        type: RESET_SUCCESS,
        meta: {
          type: 'notification',
          payload: {
            type: 'success',
            message: 'Password was successfully updated!',
          },
        },
      })

      return Promise.resolve(response)
    } catch (err) {
      dispatch({
        type: RESET_FAILURE,
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: errRespToMsg('', err), // TODO: put error message mapping a in global constants
          },
        },
      })
      return Promise.reject(err)
    }
  }
}

export const forgotPassword = email => {
  return async dispatch => {
    try {
      dispatch({ type: FORGOT })
      const response = await client(true).get(`/customers/password/${email}`)

      dispatch({ type: FORGOT_SUCCESS })

      return Promise.resolve(response)
    } catch (err) {
      dispatch({
        type: FORGOT_FAILURE,
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: errRespToMsg(FORGOT_FAILURE, err), // TODO: put error message mapping a in global constants
          },
        },
      })
      return Promise.reject(err)
    }
  }
}

export const confirmEmail = requestCode => {
  return async dispatch => {
    try {
      dispatch({ type: CONFIRM })

      const response = await client(true).post(
        `/customers/email/verify_email`,
        { requestCode },
      )

      dispatch({
        type: CONFIRM_SUCCESS,
        meta: {
          type: 'notification',
          payload: {
            type: 'success',
            message: 'Email was successfully confirmed!',
          },
        },
      })

      return Promise.resolve(response)
    } catch (err) {
      dispatch({
        type: CONFIRM_FAILURE,
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: errRespToMsg('', err), // TODO: put error message mapping a in global constants
          },
        },
      })
      return Promise.reject(err)
    }
  }
}

const createAccount = ({
  email,
  password,
  firstName,
  lastName,
  mobile,
}) => dispatch => {
  dispatch({
    type: `${REGISTER}_PENDING`,
  })
  return client(true)
    .post('/customers', {
      username: email,
      password,
      passwordConfirm: password,
      firstname: firstName,
      lastname: lastName,
      mobile,
    })
    .then(() => {
      dispatch({
        type: `${REGISTER}_FULLFILLED`,
      })
    })
    .catch(err => {
      dispatch({
        type: `${REGISTER}_REJECTED`,
      })
      throw err
    })
}

export const applyReferralCode = referredBy => dispatch => {
  dispatch({ type: `${REFERRAL_CODE}_PENDING` })
  return client()
    .put('/customers/me', {
      referredBy,
    })
    .then(() => {
      dispatch({
        type: `${REFERRAL_CODE}_FULLFILLED`,
      })
    })
    .catch(err => {
      dispatch({
        type: `${REFERRAL_CODE}_REJECTED`,
        meta: {
          type: 'notification',
          payload: {
            type: 'error',
            message: getError(err),
          },
        },
      })
    })
}

export const register = params => async dispatch => {
  try {
    await dispatch(createAccount(params))

    await dispatch(login(params.email, params.password))

    if (params.referredBy) {
      await dispatch(applyReferralCode(params.referredBy))
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const dispatchLogin = (email, password) => (
  dispatch,
  { onSuccess = () => {} },
) =>
  dispatch(login(email, password))
    .then(onSuccess)
    .then(dispatch(unsetGuestCheckoutEmail()))

export const login = (email, password) => ({
  type: LOGIN,
  payload: client(true)
    .post('/auth', {
      grant_type: 'password',
      username: email,
      password: password,
      client_id: CONFIG.CLIENT_ID,
      client_secret: get(getConfig(),'client_secret'),
    })
    .then(data => {
      return {
        ...data.data,
        issued_at: data.data.issued_at * 1000,
      }
    }),
})

export const refreshToken = refreshToken => dispatch => {
  dispatch({ type: `${REFRESH_TOKEN}_PENDING` })
  const s3Config = getConfig();
  return client(true)
    .post('/auth/token', {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: CONFIG.CLIENT_ID,
      client_secret: get(s3Config,'client_secret')
    })
    .then(data => ({
      ...data.data,
      issued_at: data.data.issued_at * 1000,
    }))
    .then(d => dispatch({ type: `${REFRESH_TOKEN}_FULFILLED`, payload: d }))
    .catch(e => {
      dispatch({ type: `${REFRESH_TOKEN}_REJECTED`, payload: e, error: true })
      dispatch(logout())
    })
}

export const logout = () => dispatch => {
  dispatch({ type: RESET_RECENT_ORDERS })
  dispatch({ type: LOGOUT, payload: api.logout() })
}

export const redirectTo = url => ({
  type: REDIRECT,
  payload: url,
})

export const persistBlacklist = [
  'isRegistering',
  'isResettingPassword',
  'isUpdatingPassword',
  'isConfirmingEmail',
  'isAuthenticating',
  'redirection',
  'error',
]

const initialState = {
  isRegistering: false,
  isResettingPassword: false,
  isUpdatingPassword: false,
  isConfirmingEmail: false,
  isAuthenticating: false,
  isAuthenticated: false,
  token: {},
  redirection: '',
  error: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_PENDING`:
      return Object.assign({}, state, {
        isAuthenticating: true,
        error: null,
      })
    case `${LOGIN}_FULFILLED`:
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        token: action.payload,
      })
    case `${LOGIN}_REJECTED`:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isAuthenticating: false,
        error: errRespToMsg('Login', action.payload),
      })

    case String(clearError):
      return { ...state, error: null }

    case `${REFRESH_TOKEN}_PENDING`:
      return Object.assign({}, state, { isAuthenticating: true })
    case `${REFRESH_TOKEN}_FULFILLED`:
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: true,
        token: action.payload,
      })
    case `${REFRESH_TOKEN}_REJECTED`:
      return Object.assign({}, state, {
        isAuthenticating: false,
        isAuthenticated: false,
      })

    case `${REGISTER}_PENDING`:
      return Object.assign({}, state, { isRegistering: true, error: null })
    case `${REGISTER}_FULFILLED`:
      return Object.assign({}, state, { isRegistering: false })
    case `${REGISTER}_REJECTED`:
      return Object.assign({}, state, {
        isRegistering: false,
        error: errRespToMsg('Registration', action.payload),
      })

    case FORGOT:
      return Object.assign({}, state, { isResettingPassword: true })
    case FORGOT_SUCCESS:
    case FORGOT_FAILURE:
      return Object.assign({}, state, { isResettingPassword: false })

    case RESET:
      return Object.assign({}, state, { isUpdatingPassword: true })
    case RESET_SUCCESS:
    case RESET_FAILURE:
      return Object.assign({}, state, { isUpdatingPassword: false })

    case CONFIRM:
      return Object.assign({}, state, { isConfirmingEmail: true })
    case CONFIRM_SUCCESS:
    case CONFIRM_FAILURE:
      return Object.assign({}, state, { isConfirmingEmail: false })

    case REDIRECT:
      return Object.assign({}, state, {
        redirection: action.payload,
      })

    case `${LOGOUT}_PENDING`:
      return Object.assign({}, state, { isAuthenticated: false, token: {} })
    default:
      return state
  }
}

export default reducer

/**
 * Selectors
 */

export const selectIsAuthenticating = state => state.auth.isAuthenticating
export const selectIsRegistering = state => state.auth.isRegistering
export const selectIsAuthenticated = state => state.auth.isAuthenticated
export const selectError = state => state.auth.error
export const selectIsResettingPassword = state => state.auth.isResettingPassword
export const selectIsUpdatingPassword = state => state.auth.isUpdatingPassword
export const selectIsConfirmingEmail = state => state.auth.isConfirmingEmail
export const selectToken = state => state.auth.token

/**
 * Internal
 */
const errRespToMsg = (action, resp) => {
  const { code, message } = get(resp, 'response.data.status') || {}

  switch (code) {
    case 100:
      return `Email doesn't exist`
    case 130100: // user doesn't exist
    case 130200: // wrong password
      return 'Incorrect credentials'
    case 130101:
      return 'Registration failed, user already exists'
    case 130102:
      if (message.includes('Invalid referral code')) {
        return 'Invalid referral code'
      }
      break
    default:
      return message || `${action} failed`
  }
}
