import React, { createContext, useEffect, useState } from 'react'
import client from 'client'
import isNode from 'detect-node'

export const ConfigContext = createContext()

export const ConfigConsumer = ConfigContext.Consumer

export const getConfig = () => {
  try {
    return JSON.parse(localStorage.getItem('heyyou-config'))
  } catch (err) {
    console.error(err.message)
    return {}
  }
}

export const ConfigProvider = ({ children }) => {
  // Do not use hook on node
  if (isNode) {
    return (
      <ConfigContext.Provider value={{}}>{children}</ConfigContext.Provider>
    )
  }

  const [config, setConfig] = useState(getConfig())

  useEffect(() => {
    client()
      .get('/config')
      .then(resp => {
        localStorage.setItem('heyyou-config', JSON.stringify(resp.data))
        setConfig(resp.data)
      })
  }, [])

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}
